<template>
  <v-container class="weather px-9 mt-5">
    <v-row>
      <v-col class="col-6 weather-col">
        <div class="px-5 pt-12 font-24 font-italic">
          &quot;I didn't see the other vehicle because it was
          <span class="highlight-text">raining</span>.&quot;
          <div
            class="pb-4 font-14"
            style="font-style: normal; margin-top: 92px"
          >
            Claim statement fragment
          </div>
        </div>
      </v-col>
      <v-col class="col-6 weather-col">
        <div class="pa-4 align-center">
          <div v-for="(item, index) in items" :key="index">
            <div
              class="
                weather-item
                pa-2
                mb-3
                d-flex
                justify-space-between
                align-center
              "
              :class="`${item.accidentTime && 'highlight-weather-item'}`"
            >
              <div class="d-flex align-center">
                <img :src="item.icon" alt="weather icon" class="mr-4" />
                <span>{{ item.degrees }}</span>
                <span
                  :class="`${item.accidentTime && 'font-weight-bold'}`"
                  class="pl-2"
                  >{{ item.description }}</span
                >
              </div>
              <div>
                {{ item.time }}
              </div>
            </div>
          </div>
          <div class="d-flex align-center justify-space-between pt-4">
            <div>Las Vegas, NV, USA</div>
            <div class="text-decoration-underline">AccuWeather.com</div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import sunny from "@/assets/img/weather/sunny.png";
import partialCloudy from "@/assets/img/weather/partial-cloudy.png";

export default {
  data() {
    return {
      items: [
        {
          degrees: "88˚",
          description: "Plenty of sun",
          time: "-1 hour",
          icon: sunny,
          accidentTime: false
        },
        {
          degrees: "88˚",
          description: "Plenty of sun",
          time: "Date & time of incident",
          icon: sunny,
          accidentTime: true
        },
        {
          degrees: "86˚",
          description: "Sun and areas of high clouds",
          time: "+1 hour",
          icon: partialCloudy,
          accidentTime: false
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.weather {
  .weather-col > div {
    background-color: rgb(241, 243, 244);
    border-radius: 6px;
    gap: 10px;
  }
  .weather-item {
    background-color: #ffffff91;
    border-radius: 6px;
    width: 95%;
    margin: 0 auto;

    &.highlight-weather-item {
      background-color: #fff;
      width: 100%;
    }
  }
}
</style>
